<template>
  <div>
    <!-- Modal. Contiene el card del listado de nóminas. -->
    <b-modal
      id="planeacionesModal"
      :title="isUpdating ? 'Editar planeación presupuestal' : 'Nueva planeación presupuestal'"
      v-model="show"
      centered
      no-close-on-esc
      no-close-on-backdrop
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <!-- Tags -->
        <div class="my-2">
          {{isUpdating ? 'Editar planeación presupuestal' : 'Nueva planeación presupuestal'}}
        </div>
      </template>

      <!-- Body -->
      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Cargando información, por favor espere..." />
          </div>
        </template>
        <template :class="{ isLoading }">
          <FormulateForm
            ref="budgetForm"
            name="budgetForm"
            v-model="formValues"
            :errors="response.errors"
            @submit="submit"
          >
            <b-row>
              <!-- Periodo fiscal -->
              <b-col cols="12" md="12">
                <FormulateInput
                  name="periodo_fiscal"
                  v-model="formValues.periodo_fiscal_id"
                  :disabled="isLoading"
                  validation="bail|required"
                  error-behavior="live"
                  :validation-messages="{
                    required: 'El periodo fiscal es requerido'
                  }"
                >
                  <template #element>
                    <x-form-group title="Periodo fiscal" required>
                      <check-authorization
                        :requiresAuthorizations="['listar periodos fiscales']"
                        :show-alert="false"
                        no-slots
                        #default="{ authorized }"
                      >
                        <x-select
                          v-model="formValues.periodo_fiscal_id"
                          dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
                          getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
                          :formatListWith="['id', 'nombre']"
                          :disabled="!authorized || isLoading"
                          no-validate
                          no-prepend
                        ></x-select>
                      </check-authorization>
                    </x-form-group>
                  </template>
                </FormulateInput>
              </b-col>
              <b-col cols="12" md="12">
                <FormulateInput
                  name="nombre"
                  label="Nombre"
                  v-model="formValues.nombre"
                  required
                  type="text"
                  validation="required"
                  validation-name="Nombre"
                />
              </b-col>
              <b-col cols="12" md="12">
                <FormulateInput
                  name="descripcion"
                  label="Descripción"
                  v-model="formValues.descripcion"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3" md="12">
                <FormulateInput
                  name="porcentaje"
                  label="Porcentaje"
                  v-model="formValues.porcentaje"
                  required
                  type="text"
                  validation="required"
                  validation-name="Porcentaje"
                />
              </b-col>
            </b-row>
          </FormulateForm>
        </template>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="isLoading"
            :loading="isLoading"
            :isEditing="isUpdating"
            :isCreating="!isUpdating"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

const formValues = () => ({
  periodo_fiscal_id: '',
  nombre: '',
  descripcion: '',
  porcentaje: 0
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})
export default {
  name: 'PlaneacionesModalCrear',

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    budget: {
      type: Number,
      required: false
    },

    isUpdating: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      isLoading: false,

      show: this.value,

      showEditForm: false,

      formValues: formValues(),

      response: response()
    }
  },

  computed: {
    getBudgetEdit () {
      return this.$store.getters['planeacionModule/getBudgetEdit']
    }
  },

  watch: {
    value (newValue) {
      this.show = newValue
    },

    show (newValue) {
      this.$emit('input', newValue)
      this.checkIfEdit()
    }
  },

  methods: {
    async submit () {
      this.isLoading = true
      this.response = response()
      console.log('SUBMIT')
      if (this.isUpdating) {
        console.log('ACTUALIZAR')
      } else {
        console.log('CREAR')
      }
      // const payload = JSON.parse(JSON.stringify(this.formValues))
      const payload = this.formValues
      const { error, message, data } = this.isUpdating
        ? await this.updateBudget(payload)
        : await this.createBudget(payload)
      this.isLoading = false
      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },
    async createBudget (form) {
      return await this.$store.dispatch('planeacionModule/createPlan', form)
    },
    async updateBudget (form) {
      return await this.$store.dispatch('planeacionModule/updatePlan', { ...form, id: this.budget })
    },
    async getBudgetById (id) {
      return await this.$store.dispatch('planeacionModule/getBudgetPlanById', { id })
    },
    resetForm () {
      this.response = response()
      this.formValues = formValues()
      this.onShow(false)
    },
    onShow (value) {
      this.show = value
    },
    onShowEditForm (value) {
      this.showEditForm = value
    },
    async loadEdit () {
      this.isLoading = true
      await this.getBudgetById(this.budget)
      const { periodo_fiscal_id, nombre, descripcion, porcentaje } = this.$store.getters['planeacionModule/getBudgetEdit']
      this.formValues.periodo_fiscal_id = periodo_fiscal_id
      this.formValues.nombre = nombre
      this.formValues.descripcion = descripcion
      this.formValues.porcentaje = porcentaje
      this.isLoading = false
    },
    checkIfEdit () {
      if (this.isUpdating && this.show) this.loadEdit()
    }
  }
}
</script>
